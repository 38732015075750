import React from "react"
import Pagination from "react-bootstrap/Pagination"
import right from "../../images/svg/right.png"
import left from "../../images/svg/left1.png"
import { range } from "../../utils"
import * as styles from "./Paginator.module.scss"
import "./Pagination.scss"

const Paginator = props => {
  const { slug, currentPage, totalPages } = props

  return (
    <Pagination className={styles.paginationContainer}>
      <Pagination.Prev
        href={
          currentPage === 2 ? `/${slug}/` : `/${slug}/page/${currentPage - 1}/`
        }
        disabled={currentPage === 1}
      >
        <img src={left} className={styles.faArrow} alt="arrow left" />
      </Pagination.Prev>
      {totalPages <= 10 ? (
        Array.from({ length: totalPages }).map((_, i) => (
          <Pagination.Item
            key={i}
            href={i === 0 ? `/${slug}/` : `/${slug}/page/${i + 1}/`}
            active={currentPage === i + 1}
          >
            {i + 1}
          </Pagination.Item>
        ))
      ) : (
        <React.Fragment>
          <Pagination.Item href={`/${slug}/`} active={currentPage === 1}>
            {1}
          </Pagination.Item>
          {currentPage > 4 && (
            <Pagination.Ellipsis className={styles.ellipsis} />
          )}
          {range(
            currentPage + 3 <= totalPages
              ? currentPage < 4
                ? 2
                : currentPage - 2
              : totalPages - 4,
            currentPage + 3 <= totalPages
              ? currentPage < 3
                ? 5
                : currentPage + 2
              : totalPages - 1
          ).map((n, i) => (
            <Pagination.Item
              key={i}
              href={`/${slug}/page/${n}/`}
              active={currentPage === n}
            >
              {n}
            </Pagination.Item>
          ))}
          {currentPage + 3 < totalPages - 1 && (
            <Pagination.Ellipsis className={styles.ellipsis} />
          )}
          <Pagination.Item
            href={`/${slug}/page/${totalPages}/`}
            active={currentPage === totalPages}
          >
            {totalPages}
          </Pagination.Item>
        </React.Fragment>
      )}
      <Pagination.Next
        href={`/${slug}/page/${currentPage + 1}/`}
        disabled={currentPage === totalPages}
      >
        <img src={right} className={styles.faArrow} alt="arrow right" />
      </Pagination.Next>
    </Pagination>
  )
}

export default Paginator

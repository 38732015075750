import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import BannerWithOutBg from "../components/common/BannerWithOutBg"
import NewsCard from "../components/common/NewsCard"
import Paginator from "../components/common/Paginator"
import MainLayout from "../layouts/MainLayout"

const NewsTemplate = ({ pageContext }) => {
  const posts = pageContext?.data
  const { totalPages, currentPage } = pageContext

  return (
    <MainLayout bgChanged={false}>
      <BannerWithOutBg
        heading="Tech News"
        subHeading="Bringing Global Startup Innovation & Funding News To You."
      />
      <section>
        <Container>
          <Row>
            {posts?.length > 0 ? (
              posts.map((post, i) => (
                <Col
                  md={i === 0 || i === 6 ? 12 : 6}
                  lg={i === 0 || i === 6 ? 8 : 4}
                  key={i}
                  style={{ padding: 15 }}
                >
                  <NewsCard post={post} />
                </Col>
              ))
            ) : (
              <Col xs={12} className="text-center">
                No Data
              </Col>
            )}
          </Row>
          <Row className="mt-5">
            <Col className="d-flex justify-content-center">
              <Paginator
                slug="technews"
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  )
}

export default NewsTemplate

export const Head = () => {
  return (
    <>
      <title>Read Trending Technology News on InvoZone Tech News World</title>
      <meta
        name="description"
        content="Find the latest technology news regarding tech trends, issues, and events only at InvoNews, InvoZone Tech News World. Click here to read more."
      ></meta>
      <meta name="robots" content="noindex, nofollow"></meta>
      <meta name="googlebot" content="noindex, nofollow"></meta>
    </>
  )
}

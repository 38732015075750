import { Link, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Button from "react-bootstrap/Button"
import * as styles from "./NewsCard.module.scss"

const NewsCard = ({ post }) => {
  const { slug, title, featuredImage, readingTime } = post
  const image = getImage(featuredImage?.localFile)

  const handleClick = () => {
    navigate(`/technews/${slug}/`)
  }
  return (
    <React.Fragment>
      <div className={styles.card}>
        <GatsbyImage
          placeholder="blurred"
          decoding="async"
          loading="lazy"
          image={image}
          alt={title}
          className={styles.NewsCardImg}
        />
        <div className={styles.overlay}>
          <div>{readingTime}</div>
          <h2 className="h5 pb-3">{title}</h2>
          <div>
            <Link to={`/technews/${slug}/`}>
              <Button className="btn_white_border">Read More</Button>
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`font-weight-bold p-2 ${styles.title}`}
        onClick={handleClick}
      >
        {title}
      </div>
    </React.Fragment>
  )
}

export default NewsCard

import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./BannerWithOutBg.module.scss"

const BannerWithOutBg = ({ heading, subHeading }) => (
  <section className={styles.section}>
    <Container className="text-center">
      <h1 className="main-banner-heading-55">{heading}</h1>
      {subHeading && <h4 className="font-weight-normal pt-3">{subHeading}</h4>}
    </Container>
  </section>
)

export default BannerWithOutBg
